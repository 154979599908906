import React, { Component } from "react";
import BasicMap from "react-spatial/components/BasicMap";
import { MapboxLayer, Layer } from "mobility-toolbox-js/ol";
import Search from "react-spatial/components/Search";
import { WebGLPoints as WebGLPointsLayer } from "ol/layer";
import { Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";

import LayerTree from "react-spatial/components/LayerTree";
import OLMap from "ol/Map";
import { defaults as defaultInteractions } from "ol/interaction";
import KeyboardPan from "ol/interaction/KeyboardPan";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import { Vector } from "ol/source";
import { GeoJSON } from "ol/format";
import { bbox } from "ol/loadingstrategy";
import Geolocation from "react-spatial/components/Geolocation";
import ScaleLine from "react-spatial/components/ScaleLine";
import Zoom from "react-spatial/components/Zoom";
import osmtogeojson from "osmtogeojson";
import { transform } from "ol/proj";
import parkbench_icon from "./img/parkbench_icon.png";
import parkbench from "./img/parkbench.png";
import cigarettes_icon from "./img/cigarettes_icon.png";
import rettes from "./img/cigarettes.png";
import location_icon from "./img/location.png";
import location_point_icon from "./img/point.png";

import bin_icon from "./img/bin_icon.png";
import bin_image from "./img/bin.png";

import wc_icon from "./img/wc_icon.png";
import wc_image from "./img/wc.png";

// import hiking_icon from './img/hiking_icon.png';
import hiking_image from "./img/hiking.png";

import postboxes_icon from "./img/postboxes_icon.png";
import boxes from "./img/postboxes.png";

import LayerService from "react-spatial/LayerService";

import Copyright from "react-spatial/components/Copyright";
import { default as SpatialPopup } from "react-spatial/components/Popup";

import { FaRegDotCircle } from "react-icons/fa";

import NorthArrowSimple from "./img/northArrow.svg";
import InfoSvg from "./img/info.svg";
import Popup from "reactjs-popup";

import NorthArrow from "react-spatial/components/NorthArrow";
import Permalink from "react-spatial/components/Permalink";

import "ol/ol.css";
import "react-spatial/themes/default/index.scss";
import "./App.scss";

class App extends Component {
  constructor() {
    super();

    this.PopupOverlayStyle = {
      overflowY: "scroll",
    };
    this.PopupContentStyle = {
      width: "80%",
    };

    const featStyleBenches = {
      symbol: {
        symbolType: "image",
        size: [36, 48],
        src: parkbench_icon,
        offset: [-0.5, 26],
      },
    };

    const featStyleCigarettes = {
      symbol: {
        symbolType: "image",
        size: [36, 48],
        src: cigarettes_icon,
        offset: [-0.5, 26],
      },
    };

    const featStylePostboxes = {
      symbol: {
        symbolType: "image",
        size: [36, 48],
        src: postboxes_icon,
        offset: [-0.5, 26],
      },
    };

    const featStyleBin = {
      symbol: {
        symbolType: "image",
        size: [36, 48],
        src: bin_icon,
        offset: [-0.5, 26],
      },
    };

    const featStyleWc = {
      symbol: {
        symbolType: "image",
        size: [36, 48],
        src: wc_icon,
        offset: [-0.5, 26],
      },
    };

    const featStyleHiking = new Style({
      stroke: new Stroke({
        color: "#7D1538",
        width: 3,
      }),
    });

    const featStyleSelected = new Style({
      stroke: new Stroke({
        color: "blue",
        width: 5,
      }),
    });

    const featStyleRouting = new Style({
      stroke: new Stroke({
        color: "darkblue",
        width: 5,
        lineDash: [10],
      }),
    });

    this.map = new OLMap({
      controls: [],
      interactions: defaultInteractions({
        //altShiftDragRotate: false,
        //pinchRotate: false,
      }).extend([new KeyboardPan({ duration: 0 })]),
    });

    var benches = new Vector({
      format: new GeoJSON(),
      loader: function (extent, resolution, projection) {
        var url = "https://wogibt.es/api/interpreter";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        var onError = function () {
          benches.removeLoadedExtent(extent);
        };
        xhr.onerror = onError;
        xhr.onload = function () {
          if (xhr.status === 200) {
            var json = osmtogeojson(JSON.parse(xhr.responseText), {
              flatProperties: true,
            });
            benches.addFeatures(
              benches.getFormat().readFeatures(json, {
                dataProjection: "EPSG:4326",
                featureProjection: "EPSG:3857",
              })
            );
            /*benches.forEachFeature(f => {
                            f.setStyle(featStyleBenches);
                        });*/
          } else {
            onError();
          }
        };
        // (` + extent.join(',') + `);
        //(47.96257147979803,7.76630401611328,48.04825091929372,7.917194366455078);
        var bbox1 = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
        var bbox2 = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
        [bbox1[1], bbox1[0]] = [bbox1[0], bbox1[1]];
        [bbox2[1], bbox2[0]] = [bbox2[0], bbox2[1]];

        xhr.send(
          `[out:json][timeout:25];
(
  node["amenity"="bench"](` +
            bbox1.join(",") +
            `,` +
            bbox2.join(",") +
            `);
);
// print results
out body;
>;
out skel qt;`
        );
      },
      strategy: bbox,
    });
    this.controller = null;
    this.selectedLayer = new Vector({});
    this.RoutingLayer = new Vector({
      format: new GeoJSON(),
    });

    var cigarettes = new Vector({
      format: new GeoJSON(),
      loader: function (extent, resolution, projection) {
        var url = "https://wogibt.es/api/interpreter";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        var onError = function () {
          cigarettes.removeLoadedExtent(extent);
        };
        xhr.onerror = onError;
        xhr.onload = function () {
          if (xhr.status === 200) {
            var json = osmtogeojson(JSON.parse(xhr.responseText), {
              flatProperties: true,
            });
            cigarettes.addFeatures(
              cigarettes.getFormat().readFeatures(json, {
                dataProjection: "EPSG:4326",
                featureProjection: "EPSG:3857",
              })
            );
            /*cigarettes.forEachFeature(f => {
                            f.setStyle(featStyleCigarettes);
                        });*/
          } else {
            onError();
          }
        };
        // (` + extent.join(',') + `);
        //(47.96257147979803,7.76630401611328,48.04825091929372,7.917194366455078);
        var bbox1 = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
        var bbox2 = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
        [bbox1[1], bbox1[0]] = [bbox1[0], bbox1[1]];
        [bbox2[1], bbox2[0]] = [bbox2[0], bbox2[1]];

        xhr.send(
          `[out:json][timeout:25];
(
  node["vending"="cigarettes"](` +
            bbox1.join(",") +
            `,` +
            bbox2.join(",") +
            `);
);
// print results
out body;
>;
out skel qt;`
        );
      },
      strategy: bbox,
    });

    var hiking = new Vector({
      format: new GeoJSON(),
      loader: function (extent, resolution, projection) {
        var url = "https://wogibt.es/api/interpreter";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        var onError = function () {
          hiking.removeLoadedExtent(extent);
        };
        xhr.onerror = onError;
        xhr.onload = function () {
          if (xhr.status === 200) {
            var json = osmtogeojson(JSON.parse(xhr.responseText), {
              flatProperties: true,
            });
            hiking.addFeatures(
              hiking.getFormat().readFeatures(json, {
                dataProjection: "EPSG:4326",
                featureProjection: "EPSG:3857",
              })
            );
            /*hiking.forEachFeature(f => {
                            f.setStyle(featStyleHiking);
                        });*/
          } else {
            onError();
          }
        };
        // (` + extent.join(',') + `);
        //(47.96257147979803,7.76630401611328,48.04825091929372,7.917194366455078);
        var bbox1 = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
        var bbox2 = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
        [bbox1[1], bbox1[0]] = [bbox1[0], bbox1[1]];
        [bbox2[1], bbox2[0]] = [bbox2[0], bbox2[1]];

        xhr.send(
          `[out:json][timeout:25];
(
  relation["route"="hiking"](` +
            bbox1.join(",") +
            `,` +
            bbox2.join(",") +
            `);
);
// print results
out body;
>;
out skel qt;`
        );
      },
      strategy: bbox,
    });

    var postbox = new Vector({
      format: new GeoJSON(),
      loader: function (extent, resolution, projection) {
        var url = "https://wogibt.es/api/interpreter";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        var onError = function () {
          postbox.removeLoadedExtent(extent);
        };
        xhr.onerror = onError;
        xhr.onload = function () {
          if (xhr.status === 200) {
            var json = osmtogeojson(JSON.parse(xhr.responseText), {
              flatProperties: true,
            });
            postbox.addFeatures(
              postbox.getFormat().readFeatures(json, {
                dataProjection: "EPSG:4326",
                featureProjection: "EPSG:3857",
              })
            );
            /*postbox.forEachFeature(f => {
                            f.setStyle(featStylePostboxes);
                        });*/
          } else {
            onError();
          }
        };
        // (` + extent.join(',') + `);
        //(47.96257147979803,7.76630401611328,48.04825091929372,7.917194366455078);
        var bbox1 = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
        var bbox2 = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
        [bbox1[1], bbox1[0]] = [bbox1[0], bbox1[1]];
        [bbox2[1], bbox2[0]] = [bbox2[0], bbox2[1]];

        xhr.send(
          `[out:json][timeout:25];
(
  node["amenity"="post_box"](` +
            bbox1.join(",") +
            `,` +
            bbox2.join(",") +
            `);
node["amenity"="post_office"](` +
            bbox1.join(",") +
            `,` +
            bbox2.join(",") +
            `);
);
// print results
out body;
>;
out skel qt;`
        );
      },
      strategy: bbox,
    });

    var bin = new Vector({
      format: new GeoJSON(),
      loader: function (extent, resolution, projection) {
        var url = "https://wogibt.es/api/interpreter";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        var onError = function () {
          bin.removeLoadedExtent(extent);
        };
        xhr.onerror = onError;
        xhr.onload = function () {
          if (xhr.status === 200) {
            var json = osmtogeojson(JSON.parse(xhr.responseText), {
              flatProperties: true,
            });
            bin.addFeatures(
              bin.getFormat().readFeatures(json, {
                dataProjection: "EPSG:4326",
                featureProjection: "EPSG:3857",
              })
            );
            /*bin.forEachFeature(f => {
                            f.setStyle(featStyleBin);
                        });*/
          } else {
            onError();
          }
        };
        // (` + extent.join(',') + `);
        //(47.96257147979803,7.76630401611328,48.04825091929372,7.917194366455078);
        var bbox1 = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
        var bbox2 = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
        [bbox1[1], bbox1[0]] = [bbox1[0], bbox1[1]];
        [bbox2[1], bbox2[0]] = [bbox2[0], bbox2[1]];

        xhr.send(
          `[out:json][timeout:25];
(
  node["amenity"="waste_basket"](` +
            bbox1.join(",") +
            `,` +
            bbox2.join(",") +
            `);
  node["bin"](` +
            bbox1.join(",") +
            `,` +
            bbox2.join(",") +
            `);
);
// print results
out body;
>;
out skel qt;`
        );
      },
      strategy: bbox,
    });

    var wc = new Vector({
      format: new GeoJSON(),
      loader: function (extent, resolution, projection) {
        var url = "https://wogibt.es/api/interpreter";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        var onError = function () {
          wc.removeLoadedExtent(extent);
        };
        xhr.onerror = onError;
        xhr.onload = function () {
          if (xhr.status === 200) {
            var json = osmtogeojson(JSON.parse(xhr.responseText), {
              flatProperties: true,
            });
            wc.addFeatures(
              wc.getFormat().readFeatures(json, {
                dataProjection: "EPSG:4326",
                featureProjection: "EPSG:3857",
              })
            );
            /*wc.forEachFeature(f => {
                            f.setStyle(featStyleWc);
                        });*/
          } else {
            onError();
          }
        };
        // (` + extent.join(',') + `);
        //(47.96257147979803,7.76630401611328,48.04825091929372,7.917194366455078);
        var bbox1 = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
        var bbox2 = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
        [bbox1[1], bbox1[0]] = [bbox1[0], bbox1[1]];
        [bbox2[1], bbox2[0]] = [bbox2[0], bbox2[1]];

        xhr.send(
          `[out:json][timeout:25];
(
  node["amenity"="toilets"](` +
            bbox1.join(",") +
            `,` +
            bbox2.join(",") +
            `);
);
// print results
out body;
>;
out skel qt;`
        );
      },
      strategy: bbox,
    });

    this.layers = [
      new MapboxLayer({
        name: "Karte",
        visible: true,
        isBaseLayer: true,

        fadeDuration: 0,

        copyright:
          'Quelle: <a href="https://www.openstreetmap.org/" target="_blank">OSM</a> Karte: <a href="https://developer.geops.io/" target="_blank">geOps</a>',

        url: "style.json?key=5cc87b12d7c5370001c1d655428da2db4aec4a3a81ac3641c220d59f",

        radioGroup: "radio",
        tabIndex: 1,
      }),
    ];

    this.layers.push(
      new Layer({
        name: "Cigarettes",
        properties: {
          img: rettes,
          hideInLegend: true,
        },
        visible: false,

        olLayer: new WebGLPointsLayer({
          source: cigarettes,
          minZoom: 12,
          style: featStyleCigarettes,
        }),
      })
    );
    this.layers.push(
      new Layer({
        name: "Toilets",
        properties: {
          img: wc_image,
        },
        visible: true,
        olLayer: new WebGLPointsLayer({
          source: wc,
          minZoom: 12,
          style: featStyleWc,
        }),
      })
    );

    this.layers.push(
      new Layer({
        name: "Bin",
        properties: {
          img: bin_image,
        },
        visible: false,

        olLayer: new WebGLPointsLayer({
          source: bin,
          minZoom: 12,
          style: featStyleBin,
        }),
      })
    );

    this.layers.push(
      new Layer({
        name: "Postboxes",
        properties: {
          img: boxes,
        },
        visible: false,

        olLayer: new WebGLPointsLayer({
          source: postbox,
          minZoom: 12,
          style: featStylePostboxes,
        }),
      })
    );

    this.layers.push(
      new Layer({
        name: "Parkbenches",
        properties: {
          img: parkbench,
        },
        visible: false,

        olLayer: new WebGLPointsLayer({
          source: benches,
          minZoom: 12,
          style: featStyleBenches,
        }),
      })
    );
    this.layers.push(
      new Layer({
        name: "Hiking trails",
        properties: {
          img: hiking_image,
        },
        visible: false,

        olLayer: new VectorLayer({
          source: hiking,
          minZoom: 12,
          style: featStyleHiking,
        }),
      })
    );

    this.layers.push(
      new Layer({
        name: "Select",

        properties: {
          hideInLegend: true,
        },
        visible: true,

        olLayer: new VectorLayer({
          source: this.selectedLayer,
          style: featStyleSelected,
        }),
      })
    );

    this.layers.push(
      new Layer({
        name: "Routing",

        properties: {
          hideInLegend: true,
        },
        visible: true,

        olLayer: new VectorLayer({
          source: this.RoutingLayer,
          style: featStyleRouting,
        }),
      })
    );

    this.layerService = new LayerService(this.layers, {});

    this.center = [1149545, 6683999];
    this.onFeaturesClick = this.onFeaturesClick.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.heading = 0;
    this.orientedLocation = false;
    this.brokenLocation = false;
    this.state = {
      featureClicked: null,
      featureClickedStyle: null,
      geolocationFeature: null,
      RoutingLayer: null,
      abortController: null,
      RoutingTarget: [0, 0],
      lastRoutedPosition: [0, 0],
      lastSeenPosition: [0, 0],
      styleLocation: new Style({
        image: new Icon({
          src: location_point_icon,
          anchor: [49, 63],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          rotation: 0,
          rotateWithView: true,
        }),
      }),
    };
  }

  onFeaturesClick(features) {
    this.selectedLayer.clear();

    /*const { featureClicked,  featureClickedStyle } = this.state;
    if (featureClicked) {
        featureClicked.setStyle(featureClickedStyle);
    }*/
    if (features.length && (!('type' in features[0].values_ && features[0].values_.type === 'line_hpedestrian'))) {
      /*  this.setState({
      featureClickedStyle: features[0].style_
        });
        features[0].setStyle(this.featStyleSelected);*/
      this.selectedLayer.addFeature(features[0]);
      const position = transform(
        [
          features[0].values_.geometry.flatCoordinates[0],
          features[0].values_.geometry.flatCoordinates[1],
        ],
        this.map.getView().getProjection().getCode(),
        "EPSG:4326"
      ).reverse();
      this.setState({ RoutingTarget: position });

      const { lastSeenPosition, RoutingLayer, abortController } = this.state;

      if (lastSeenPosition[0] !== 0) {

        if (abortController) {
          abortController.abort();
        }
        let controller = new AbortController();
        this.setState({ abortController: controller });
        const signal = controller.signal;
        fetch(
          "https://api.geops.io/routing/v1/?via=" +
            lastSeenPosition.join(",") +
            "|" +
            position.join(",") +
            "&mot=foot&key=5cc87b12d7c5370001c1d655428da2db4aec4a3a81ac3641c220d59f",
          { signal }
        )
          .then((response) => response.json())
          .then((data) => {
            RoutingLayer.clear();
            this.setState({
              lastRoutedPosition: [lastSeenPosition[0], lastSeenPosition[1]],
            });
            RoutingLayer.addFeatures(
              RoutingLayer.getFormat().readFeatures(data, {
                dataProjection: "EPSG:4326",
                featureProjection: "EPSG:3857",
              })
            );
          });
      }
      this.setState({
        featureClicked: features[0],
      });
    } else {
      return false;
    }
    
  }
  onCloseClick() {
    this.selectedLayer.clear();
    /*const { featureClicked,  featureClickedStyle } = this.state;
    featureClicked.setStyle(featureClickedStyle);*/
    this.setState({ featureClicked: null });
  }
  onInputClick(layer, toggle = false) {
    if (toggle) {
      this.onToggle(layer);
    } else {
      layer.setVisible(!layer.getVisible);
    }
  }
  f(layer, layerTreeComp) {
    return (
      <>
        <div className={layer.visible ? "" : "grayOut"}>
          <img
            src={layer.get("img")}
            alt="test"
            onClick={() => layerTreeComp.onInputClick(layer)}
          />
        </div>
      </>
    );
  }

  activateOrientation() {
    if ("ondeviceorientationabsolute" in window) {
      window.addEventListener(
        "deviceorientationabsolute",
        this.handleOrientation
      );
    } else {
      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        DeviceOrientationEvent.requestPermission()
          .then((permissionState) => {
            if (permissionState === "granted") {
              window.addEventListener(
                "deviceorientation",
                this.handleOrientation,
                true
              );
            }
          })
          .catch(alert);
      } else {
        window.addEventListener("deviceorientation", this.handleOrientation, true);
      }
    }
  }

  handleOrientation = (event) => {
    const { geolocationFeature } = this.state;
    if (geolocationFeature) {
      var compassdir;
      if (event.webkitCompassHeading) {
        // Apple works only with this, alpha doesn't work
        compassdir = event.webkitCompassHeading;

        geolocationFeature.set("rotation", compassdir * ((2 * Math.PI) / 360)); // The change event will be triggered automaticallys
      } else if (event.alpha) {
        if (event.absolute) {
          compassdir = 360 - event.alpha;
        } else {
          // not absolute event; e.g. firefox, lets disable everything again
          alert("broken direction");
          this.brokenLocation = true;
          window.removeEventListener("deviceorientation", this.handleOrientation, true);
        }

        geolocationFeature.set("rotation", compassdir * ((2 * Math.PI) / 360)); // The change event will be triggered automatically
      }
    }
  };

  componentDidMount() {
    this.setState({ RoutingLayer: this.RoutingLayer });
    fetch(
      "https://api.ipgeolocation.io/ipgeo?apiKey=7fe2f8875d154a8c9c1378ec0162fda3"
    )
      .then((response) => response.json())
      .then((data) => {
        const position = transform(
          [data.longitude, data.latitude],
          "EPSG:4326",
          this.map.getView().getProjection().getCode()
        );

        this.map.getView().setCenter(position);
        this.map.getView().setZoom(14);
      });

    /*if ('ondeviceorientationabsolute' in window) {
            window.addEventListener("deviceorientationabsolute", this.handleOrientation);
        } else {
            window.addEventListener("deviceorientation", this.handleOrientation);
        }*/
  }

  render() {
    const { featureClicked } = this.state;

    const items = [];
    if (featureClicked) {
      if (featureClicked.values_.name) {
        items.push(
          <li key={"name"}>
            <b>Name:</b> {featureClicked.values_.name}
          </li>
        );
      }
      if (featureClicked.values_.operator) {
        items.push(
          <li key={"operator"}>
            <b>Betreiber:</b> {featureClicked.values_.operator}
          </li>
        );
      }
      if (featureClicked.values_.collection_times) {
        items.push(
          <li key={"collection_times"}>
            <b>Abholzeiten:</b> {featureClicked.values_.collection_times}
          </li>
        );
      }
      if (featureClicked.values_.amenity) {
        items.push(
          <li key={"amnity"}>
            <b>Typ:</b>{" "}
            {featureClicked.values_.amenity
              .replace("bench", "Bank")
              .replace("post_box", "Briefkasten")
              .replace("post_office", "Poststelle")
              .replace("waste_basket", "Mülleimer")
              .replace("toilets", "Toiletten")
              .replace("vending_machine", "Automat")}
          </li>
        );
      }
      if (featureClicked.values_.fee) {
        items.push(
          <li key={"fee"}>
            <b>Gebühr:</b>{" "}
            {featureClicked.values_.fee
              .replace("yes", "Ja")
              .replace("no", "Nein")}
          </li>
        );
      }
      if (featureClicked.values_.wheelchair) {
        items.push(
          <li key={"wheelchair"}>
            <b>Rollstuhlgerecht:</b>{" "}
            {featureClicked.values_.wheelchair
              .replace("yes", "Ja")
              .replace("no", "Nein")}
          </li>
        );
      }
    }

    return (
      <div
        style={{
          position: "fixed",
          height: "100%",
          width: "100%",
        }}
      >
        <BasicMap
          map={this.map}
          zoom={6.5}
          layers={this.layers}
          center={this.center}
          tabIndex={1}
          onFeaturesClick={this.onFeaturesClick}
          featuresClickOptions={{
              hitTolerance: 10
        }}
        />
        <Permalink
          map={this.map}
          layerService={this.layerService}
          isLayerHidden={(l) =>
            l.get("hideInLegend") ||
            this.layerService.getParents(l).some((pl) => pl.get("hideInLegend"))
          }
        />
        <SpatialPopup
          map={this.map}
          header="Info"
          feature={featureClicked}
          onCloseClick={this.onCloseClick}
        >
          <ul>{items}</ul>
        </SpatialPopup>
        <Geolocation
          map={this.map}
          alwaysRecenterToPosition={false}
          children=<span
            className={"rs-geolocation"}
            style={{ top: "0px", right: "0px" }}
            onClick={() => this.activateOrientation()}
          >
            <FaRegDotCircle focusable={false} />
          </span>
          colorOrStyleFunc={(feature) => {
            const { styleLocation, geolocationFeature } = this.state;
            if (!geolocationFeature || feature !== geolocationFeature) {
              this.setState({ geolocationFeature: feature });
            }

            if (feature.get("rotation")) {
              if (!this.orientedLocation) {
                this.orientedLocation = true;

                styleLocation.setImage(
                  new Icon({
                    src: location_icon,
                    anchor: [49, 63],
                    anchorXUnits: "pixels",
                    anchorYUnits: "pixels",
                    rotation: 0,
                    rotateWithView: true,
                  })
                );
              }
              styleLocation.getImage().setRotation(feature.get("rotation"));
            if (this.orientedLocation && this.brokenLocation) {
              styleLocation.setImage(
                new Icon({
                  src: location_point_icon,
                  anchor: [49, 63],
                  anchorXUnits: "pixels",
                  anchorYUnits: "pixels",
                  rotation: 0,
                  rotateWithView: true,
                })
               );
             }

            }

            return styleLocation;
          }}
          onSuccess={(map, [latitude, longitude]) => {
            const {
              lastRoutedPosition,
              RoutingLayer,
              RoutingTarget,
              abortController,
            } = this.state;

            this.setState({ lastSeenPosition: [latitude, longitude] });
            if (
              RoutingTarget[0] !== 0 &&
              (Math.abs(lastRoutedPosition[0] - latitude) > 0.0001 ||
                Math.abs(lastRoutedPosition[1] - longitude) > 0.0001)
            ) {
              if (abortController) abortController.abort();
              let controller = new AbortController();
              this.setState({ abortController: controller });
              const signal = controller.signal;
              fetch(
                "https://api.geops.io/routing/v1/?via=" +
                  latitude +
                  "," +
                  longitude +
                  "|" +
                  RoutingTarget.join(",") +
                  "&mot=foot&key=5cc87b12d7c5370001c1d655428da2db4aec4a3a81ac3641c220d59f",
                { signal }
              )
                .then((response) => response.json())
                .then((data) => {
                  this.setState({ lastRoutedPosition: [latitude, longitude] });
                  RoutingLayer.clear();
                  RoutingLayer.addFeatures(
                    RoutingLayer.getFormat().readFeatures(data, {
                      dataProjection: "EPSG:4326",
                      featureProjection: "EPSG:3857",
                    })
                  );
                });
            }
          }}
        />
        <ScaleLine map={this.map} />
        <Zoom map={this.map} zoomSlider />
        <LayerTree
          layerService={this.layerService}
          isItemHidden={(l) => l.isBaseLayer || l.get("hideInLegend")}
          renderItemContent={this.f}
        />
        }
        <Copyright map={this.map} />
        <Search
          map={this.map}
          limit={5}
          apiKey="5cc87b12d7c5370001c1d655428da2db4aec4a3a81ac3641c220d59f"
          onSelect={({ geometry }) => {
            this.map.getView().setCenter(fromLonLat(geometry.coordinates));
            if (this.map.getView().getZoom() <= 15)
              this.map.getView().setZoom(14);
          }}
          autocompleteProps={{
            textFieldProps: {
              label: "Search for stops",
            },
          }}
        />
        <NorthArrow
          map={this.map}
          children=<img
            onClick={() => this.map.getView().setRotation(0)}
            alt=""
            src={NorthArrowSimple}
          />
        />
        <Popup
          trigger={(open) => (
            <div className="rs-info" open={open}>
              {" "}
              <img src={InfoSvg} height={37} alt="Info" />
            </div>
          )}
          closeOnDocumentClick
          overlayStyle={this.PopupOverlayStyle}
          contentStyle={this.PopupContentStyle}
          modal
        >
          <div>
            <h1>wogibt.es</h1>
            <a href="https://play.google.com/store/apps/details?id=com.weber.wogibtes&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                style={{'max-width': '100%'}}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>

            <div>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/those-icons"
                title="Those Icons"
              >
                Those Icons
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
            <div>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/freepik"
                title="Freepik"
              >
                Freepik
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
            <div>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/eucalyp"
                title="Eucalyp"
              >
                Eucalyp
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
            <div>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/smashicons"
                title="Smashicons"
              >
                Smashicons
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
            <div>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/kiranshastry"
                title="Kiranshastry"
              >
                Kiranshastry
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
            <div>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/smashicons"
                title="Smashicons"
              >
                Smashicons
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
            <div>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/freepik"
                title="Freepik"
              >
                Freepik
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
            <div>
              Icons made by{" "}
              <a href="https://www.flaticon.com/authors/bqlqn" title="bqlqn">
                bqlqn
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>

            <div className="impressum">
              <h1>Impressum</h1>
              <p>Angaben gemäß § 5 TMG</p>
              <p>
                David Weber <br />
                Kunzenweg 25
                <br />
                79117 Freiburg im Breisgau <br />
              </p>
              <p>
                {" "}
                <strong>Vertreten durch: </strong>
                <br />
                David Weber
                <br />
              </p>
              <p>
                <strong>Kontakt:</strong> <br />
                Telefon: O17O - 888711O
                <br />
                E-Mail:
                &#100;&#97;&#118;&#105;&#100;&#119;&#101;&#98;&#101;&#114;&#97;&#112;&#112;&#115;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;
                <br />
              </p>
              <p>
                <strong>Haftungsausschluss: </strong>
                <br />
                <br />
                <strong>Haftung für Inhalte</strong>
                <br />
                <br />
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
                sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die
                auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese Inhalte umgehend entfernen.
                <br />
                <br />
                <strong>Haftung für Links</strong>
                <br />
                <br />
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
                <br />
                <br />
                <strong>Urheberrecht</strong>
                <br />
                <br />
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
                <br />
                <br />
                <strong>Datenschutz</strong>
                <br />
                <br />
                Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                personenbezogener Daten möglich. Soweit auf unseren Seiten
                personenbezogene Daten (beispielsweise Name, Anschrift oder
                eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
                stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                ausdrückliche Zustimmung nicht an Dritte weitergegeben. <br />
                Wir weisen darauf hin, dass die Datenübertragung im Internet
                (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
                aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                durch Dritte ist nicht möglich. <br />
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
                angeforderter Werbung und Informationsmaterialien wird hiermit
                ausdrücklich widersprochen. Die Betreiber der Seiten behalten
                sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                <br />
                <br />
                <br />
                <strong>Google Analytics</strong>
                <br />
                <br />
                Diese Website benutzt Google Analytics, einen Webanalysedienst
                der Google Inc. (''Google''). Google Analytics verwendet sog.
                ''Cookies'', Textdateien, die auf Ihrem Computer gespeichert
                werden und die eine Analyse der Benutzung der Website durch Sie
                ermöglicht. Die durch den Cookie erzeugten Informationen über
                Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse)
                wird an einen Server von Google in den USA übertragen und dort
                gespeichert. Google wird diese Informationen benutzen, um Ihre
                Nutzung der Website auszuwerten, um Reports über die
                Websiteaktivitäten für die Websitebetreiber zusammenzustellen
                und um weitere mit der Websitenutzung und der Internetnutzung
                verbundene Dienstleistungen zu erbringen. Auch wird Google diese
                Informationen gegebenenfalls an Dritte übertragen, sofern dies
                gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
                Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre
                IP-Adresse mit anderen Daten der Google in Verbindung bringen.
                Sie können die Installation der Cookies durch eine entsprechende
                Einstellung Ihrer Browser Software verhindern; wir weisen Sie
                jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
                sämtliche Funktionen dieser Website voll umfänglich nutzen
                können. Durch die Nutzung dieser Website erklären Sie sich mit
                der Bearbeitung der über Sie erhobenen Daten durch Google in der
                zuvor beschriebenen Art und Weise und zu dem zuvor benannten
                Zweck einverstanden.
                <br />
                <br />
                <strong>Google AdSense</strong>
                <br />
                <br />
                Diese Website benutzt Google Adsense, einen Webanzeigendienst
                der Google Inc., USA (''Google''). Google Adsense verwendet sog.
                ''Cookies'' (Textdateien), die auf Ihrem Computer gespeichert
                werden und die eine Analyse der Benutzung der Website durch Sie
                ermöglicht. Google Adsense verwendet auch sog. ''Web Beacons''
                (kleine unsichtbare Grafiken) zur Sammlung von Informationen.
                Durch die Verwendung des Web Beacons können einfache Aktionen
                wie der Besucherverkehr auf der Webseite aufgezeichnet und
                gesammelt werden. Die durch den Cookie und/oder Web Beacon
                erzeugten Informationen über Ihre Benutzung dieser Website
                (einschließlich Ihrer IP-Adresse) werden an einen Server von
                Google in den USA übertragen und dort gespeichert. Google wird
                diese Informationen benutzen, um Ihre Nutzung der Website im
                Hinblick auf die Anzeigen auszuwerten, um Reports über die
                Websiteaktivitäten und Anzeigen für die Websitebetreiber
                zusammenzustellen und um weitere mit der Websitenutzung und der
                Internetnutzung verbundene Dienstleistungen zu erbringen. Auch
                wird Google diese Informationen gegebenenfalls an Dritte
                übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
                Dritte diese Daten im Auftrag von Google verarbeiten. Google
                wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google
                in Verbindung bringen. Das Speichern von Cookies auf Ihrer
                Festplatte und die Anzeige von Web Beacons können Sie
                verhindern, indem Sie in Ihren Browser-Einstellungen ''keine
                Cookies akzeptieren'' wählen (Im MS Internet-Explorer unter
                ''Extras > Internetoptionen > Datenschutz > Einstellung''; im
                Firefox unter ''Extras > Einstellungen > Datenschutz >
                Cookies''); wir weisen Sie jedoch darauf hin, dass Sie in diesem
                Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
                voll umfänglich nutzen können. Durch die Nutzung dieser Website
                erklären Sie sich mit der Bearbeitung der über Sie erhobenen
                Daten durch Google in der zuvor beschriebenen Art und Weise und
                zu dem zuvor benannten Zweck einverstanden.
              </p>
              <br />
              Website Impressum erstellt durch{" "}
              <a href="https://www.impressum-generator.de">
                impressum-generator.de
              </a>{" "}
              von der{" "}
              <a href="https://www.kanzlei-hasselbach.de/">
                Kanzlei Hasselbach
              </a>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default App;
